import classnames from "classnames";
import React from "react";

const Spinner: React.FC<{ small?: boolean }> = ({ small }) => {
  return (
    <div
      className={classnames(
        "animate-spin rounded-full border-cream-500 border-t-transparent",
        small
          ? "w-[20px] h-[20px] border-[3px]"
          : "w-[40px] h-[40px] border-[5px]"
      )}
    />
  );
};

export default Spinner;
