import dynamic from "next/dynamic";
import * as React from "react";

import Spinner from "./core/Spinner/Index";

const SsrLoading: React.FC<Record<string, unknown>> = () => {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-cream-100">
      <Spinner />
    </div>
  );
};

function delay(component: React.FC) {
  return () => Promise.resolve(component);
}

export function withoutSsr(component: React.FC) {
  return dynamic(delay(component), {
    ssr: false,
    loading: SsrLoading
  });
}
